import * as React from 'react';

import './footer.scss';

const Footer = () => (
  <footer className="footer">
    <div className="footer__container">
      <div className="footer__policy">Season One: 0db1c36331b3b789f31d596274e210e0649ae0d4e86bb9a7c92a7482</div>
      <div className="footer__policy">Season Two: 571172ef85da56912bfe4d198e7b54d1404b0da5301bcdd2014d1d64</div>
      <div className="footer__policy">Wicked Unts: 2806f0efeb7ebfc5b55a1adcb94edf104c36fe3f27ad6f5a6de2d998</div>
      <div className="footer__policy">Festive Unts: e907ea93c53b832d8c258ace870a9fd55d3ad895d15690797bd31930</div>

      <br />
      ©
      {' '}
      {new Date().getFullYear()}
      {' '}
      BITGEM PTY LTD ABN 74 163 206 102
      {' '}
      , Built by a bunch of drongos.
    </div>
  </footer>
);

export default Footer;
