/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import * as React from 'react';
import PropTypes from 'prop-types';

import Footer from './footer';
import './layout.scss';

const Layout = ({ children }) => (
  <div className="layout">
    <div
      className="layout__container"
    >
      <main className="layout__main">{children}</main>
      <Footer />
    </div>
  </div>
);

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
